import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submit", "spinner"]

  disable() {
    this.spinnerTarget.classList.remove("hidden")
    this.submitTarget.textContent = "Wait..."
    this.submitTarget.disabled = true
    this.submitTarget.classList.add("opacity-50", "cursor-not-allowed")
  }
}
